@import "core/index.scss";
.account-page-loyalty-info {
	.info-imagewrap {
    position: relative;
    padding-bottom: 44.43548387%;
    background-color: $color-bg;
    margin-bottom: 4rem;

    img {
      background-color: transparent;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: $radius-general;
    }
  }

  .info-status {
    box-shadow: $shadow-big;
		background-color: $color-blue-bg;
		padding: 3rem;
		border-radius: $radius-general;
		border: .1rem solid $color-gray;
    margin-bottom: 3rem;
  }

    .status-loader {
      background-color: $color-blue-bg;
      border-radius: $radius-general;

      .loaderelem-icon {
        color: $color-blue;
      }
    }

    .status-heading {
      @include font-medium;
      font-size: 2.4rem;
    }

    .status-message {
      margin-top: 1rem;
      line-height: 1.2em;
    }

    .status-points {
      @include font-extrabold;
      display: inline-block;
      font-size: 1.2em;
      color: $color-blue;
      
      &.placeholder {
        width: 2rem;
        height: 1em;
      }
    }

  .info-title {
    @include font-extrabold;
    color: $color-blue;
    
    @include mediaMin {
      font-size: 3rem;
    }
  }
}